import React, {Component} from 'react'
import '../../StyleSheets/Admin/deleteuser.css'
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

class DeleteUser extends Component{
    constructor(props){
        super(props);

        this.handleSubmit = this.handleSubmit.bind(this)
    }
    
    handleSubmit(){
        const token = sessionStorage.getItem("Token");
        fetch(`https://wxloggerauthapi.azurewebsites.net/user/user?userid=${this.props.user.userId}`,{
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response =>{
            if(!response.ok){
                throw new Error('unauthorized')
            }
                
            return response.json()
        })
        .then((json) => {
            console.log("delete sucess")
            this.props.updateUsers()
            this.props.handleClose()
        })
        .catch(error => {
            console.log("something failed")
        })
    }

    render(){
        return(
            <div className="dialogText">
                <h2>Delete User</h2>
                <p>By completing this action you will permanently the user for {this.props.user.firstName} {this.props.user.lastName}.</p>
                <DialogActions>
                    <Button onClick={this.handleSubmit} color="secondary">
                        Delete
                    </Button>
                    <Button onClick={this.props.handleClose} color="primary">
                        Cancel
                    </Button>
                </DialogActions>
            </div>
        )
    }
}

export default DeleteUser