import React, {Component} from 'react'
import '../../StyleSheets/login.css'
import { Link } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid'
import { Button } from '@material-ui/core';

class LoginComponent extends Component{
    constructor(props){
        super(props);
        this.state = {
            email: '',
            password: '',
            error: <div/>
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleLogin = this.handleLogin.bind(this)
        this.handleEnter = this.handleEnter.bind(this)
        this.displayError = this.displayError.bind(this)
    }

    handleChange(event) {
        event.preventDefault();
        const name = event.target.name
        const value = event.target.value
        this.setState({[name]: value})
    }

    handleLogin(){
        fetch('https://wxloggerauthapi.azurewebsites.net/token',{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + Buffer.from(this.state.email + ":" + this.state.password).toString('base64')
            }
        })
        .then(response =>{
            if(!response.ok){
                throw new Error('unauthorized')
            }
                
            return response.json()
        })
        .then((json) => {
            sessionStorage.setItem('Token', json.token)
            sessionStorage.setItem("RefreshToken", json.refreshToken)

        return fetch('https://wxloggerconfigurationapi.azurewebsites.net/v1/datasources',{headers: {'Authorization': 'Bearer ' + json.token}})
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem Getting Data Sources')
            
            return response.json()
        })
        .then((json) => {
            sessionStorage.setItem("datasources", JSON.stringify(json))
            var telemetrySources = json.filter(source => source.sourceTypeId === 1)
            var telemetrySourceOfRecord = telemetrySources.filter(source => source.isSourceOfRecord === true)[0]
            
            return telemetrySourceOfRecord
        })
        .then((sourceOfRecord)=>{
            const history = this.props.history
            var sourceId = sourceOfRecord.sourceId
            history.push(`/dash/telemetry/${sourceId}`)
        })
        .catch(error => {
            this.setState({error: true})
            this.timer = setInterval(() => this.setState({error: false}), 5000)
        }) 
    }

    handleEnter(event){
        if(event.key === "Enter"){
            this.handleLogin()
        }
    }

    displayError(){
        if(this.state.error === true){
            return <div className="loginError"><p>Invalid Login Credentials</p></div>
        }else{
            return<div/>
        }
    }

    render(){
        
        return(
            <div>
               <Grid
                    container
                    spacing={5}
                    alignItems="center"
                    justify="center"
                >
                    <Grid item xs={4}>
                        <Card className="loginCard">
                            <CardContent>
                                <form>
                                    <div>
                                        <TextField
                                            label="Email Address"
                                            name="email"
                                            value={this.state.email}
                                            onChange={this.handleChange}
                                            fullWidth
                                        />  
                                    </div>
                                    <div>
                                        <TextField
                                            label="Password"
                                            type="password"
                                            name="password" 
                                            onChange={this.handleChange} 
                                            onKeyDown={this.handleEnter}
                                            fullWidth
                                        />
                                    </div>
                                </form>
                                <div className="submitButton">
                                    <Button
                                        type="submit" 
                                        fullWidth
                                        onClick={this.handleLogin}
                                        size="large"
                                        variant="outlined"
                                    >
                                        Login
                                    </Button>
                                </div>    
                                {this.displayError()}
                                <div className="resetLink">
                                    <Link to="/resetrequest">Forgot Password</Link>
                                </div>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>   
            </div>
        )
    }
}

export default LoginComponent