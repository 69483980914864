import React from 'react'
import {getRole} from '../../Utils'
import Assessment from '@material-ui/icons/Assessment'
import Timeline from "@material-ui/icons/Timeline"
import SupervisorAccount from '@material-ui/icons/SupervisorAccount'
import EventNote from '@material-ui/icons/EventNote'
import Assignment from '@material-ui/icons/Assignment'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Link} from 'react-router-dom'
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Divider } from '@material-ui/core';

const allAvailableRoutes = [
    {
        id: 1,
        route: "/dash",
        icon: <Assessment/>,
        text: "Dashboard",
        roles: ["observer", "admin", "user", "super"],
        isParent: true,
        sourceTypes: [1, 2, 3, 5],
        children: []
    },
    {
        id: 2,
        route: "/season",
        icon: <Timeline/>,
        text: "Season",
        roles: ["observer", "admin", "user", "super"],
        isParent: false
    },
    {
        id: 3,
        route: "/submit",
        icon: <EventNote/>,
        text: "Submit",
        roles: ["observer", "admin", "super"],
        isParent: true,
        sourceTypes: [2, 3, 4],
        children: []
    },
    {
        id: 4,
        route: "/reports",
        icon: <Assignment/>,
        text: "Reports",
        roles: ["observer", "admin", "super"],
        isParent: false
    },
    {
        id: 5,
        route: "/admin",
        icon: <SupervisorAccount/>,
        text: "Admin",
        roles: ["admin", "super"],
        isParent: false
    }
]

const sourceTypes = [
    {
        sourceTypeId: 1,
        sourceTypeDescription: "telemetry",
        siteDisplay: "Telemetry"
    },
    {
        sourceTypeId: 2,
        sourceTypeDescription: "observation",
        siteDisplay: "Observations"
    },
    {
        sourceTypeId: 3,
        sourceTypeDescription: "snowstake",
        siteDisplay: "Snow Stake"
    },
    {
        sourceTypeId: 4,
        sourceTypeDescription: "mountainstats",
        siteDisplay: "Mountain Stats"
    },
    {
        sourceTypeId: 5,
        sourceTypeDescription: "forecast",
        siteDisplay: "Forecast"
    }
]

const LinkService =  {

    buildLinksBy(role){
        var routes = []
        
        allAvailableRoutes.forEach(route => {
            if(route.roles.includes(role)){
                routes.push(route)
            }
        })

        return routes
    },

    appendDescription(datasources){
        datasources.forEach(source =>{
            var sourceType = sourceTypes.find(item => item.sourceTypeId === source.sourceTypeId)
            source.sourceTypeDescription = sourceType.sourceTypeDescription
            source.siteDisplay = sourceType.siteDisplay
        })
    },

    appendDatasources(routes, datasources){
        routes.forEach(route =>{
            route.children = []
            datasources = datasources.filter(x => x.isActive === true)
            datasources.forEach(source =>{
                if(!route.isParent)
                    return
                
                if(route.sourceTypes.includes(source.sourceTypeId))
                    route.children.push(source)

                return
            })
        })
    },

    buildList(parents, handleClick, openDash, openObs){
        var list = []
        parents.forEach(parent =>{
            if(parent.isParent === false){
                list.push(
                    <ListItem button component={Link} to={parent.route} key={parent.id}>
                        <ListItemIcon>{parent.icon}</ListItemIcon>
                        <ListItemText>{parent.text}</ListItemText>
                    </ListItem>
                )
            }
            else{
                var newlist = []
                parent.children.forEach(child =>{
                    var subtitle = ""
                    var likeDatasources = parent.children.filter(item => item.sourceTypeId === child.sourceTypeId)
                    if( likeDatasources.length > 1){
                        subtitle = ` - ${child.displayName}`
                    }
                    newlist.push(
                        <ListItem 
                            button 
                            component={Link} 
                            to={parent.route + '/' + child.sourceTypeDescription + '/' + child.sourceId} 
                            key={child.sourceId} 
                            >
                            <p>
                                {child.siteDisplay}
                                {subtitle}
                            </p>
                        </ListItem>
                    )
                })
                var open
                if(parent.id === 1){
                    open = openDash
                }
                if(parent.id === 3){
                    open = openObs
                }
                list.push(
                    <div key={parent.id}>
                        <ListItem button onClick={()=>{handleClick(parent.id)}}>
                            <ListItemIcon>{parent.icon}</ListItemIcon>
                            <ListItemText>{parent.text}</ListItemText>
                            {open ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Divider/>
                                <List>
                                    {newlist}
                                </List>
                            <Divider/>
                        </Collapse>
                    </div>
                )
            }
        })
        return list
    },

    getLinks(handleClick, openDash, openObs){
        var routes = []

        if(!sessionStorage.getItem("Token") || !sessionStorage.getItem("datasources")){
            return routes
        }
            
        var role = getRole()

        routes = this.buildLinksBy(role)

        var datasourcesString = sessionStorage.getItem("datasources")

        var datasources = JSON.parse(datasourcesString)

        this.appendDescription(datasources)

        this.appendDatasources(routes, datasources)

        var list = this.buildList(routes, handleClick, openDash, openObs)
        
        return list
    },

    
}

export default LinkService