import React from 'react';
import { TableContainer, Table,  TableHead, TableBody, TableRow, TableCell } from "@material-ui/core"

const SnowStakeTable = (props) => {
    return(
        <TableContainer>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell>Height New 24</TableCell>
                        <TableCell>Height New 48</TableCell>
                        <TableCell>Height New 72</TableCell>
                        <TableCell>Height Snow</TableCell>
                        <TableCell>Total Snowfall</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">{props.snowStake.heightNew}"</TableCell>
                        <TableCell>{props.snowStake.heightNew48}"</TableCell>
                        <TableCell>{props.snowStake.heightNew72}"</TableCell>
                        <TableCell>{props.snowStake.heightSnow}"</TableCell>
                        <TableCell>{props.snowStake.totalSnowfall}"</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default SnowStakeTable