import React, {Component} from 'react';
import DayPicker, {DateUtils} from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import '../../StyleSheets/daterangepicker.css'

class DateRangePickerComponent extends Component{
    constructor(props) {
        super(props);
        this.state={
        }
        this.handleDayClick = this.handleDayClick.bind(this);
    }
    
    handleDayClick(day) {
        this.setState({error: <div/>})
        const range = DateUtils.addDayToRange(day, this.state);
        this.setState(range);
        if(range.to && range.from){
            this.props.update(range.from.toLocaleDateString("en-US"), range.to.toLocaleDateString("en-US"))
        }
    }

    render(){
        const { from, to } = this.state;
        const modifiers = { start: from, end: to };
        return(
            <div className="row justify-contents-center">
                {this.state.error}
                <DayPicker
                className="Selectable"
                selectedDays={[from, { from, to }]}
                modifiers={modifiers}
                onDayClick={this.handleDayClick}
                />
            </div>
        )
    }

}

export default DateRangePickerComponent