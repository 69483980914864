import React, {Component} from 'react';
import ObservationTable from './Tables/ObservationTable';
import {Grid, Card, TextField, Button, FormControl, InputLabel, Select, Tooltip} from '@material-ui/core';
import DatePickerComponent from '../DatePickers/DatePickerComponent';
import {getName} from '../../Utils';
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

const backdropStyle = {
    color: '#fff',
    zIndex: 9999
}

class SubmitObservationComponent extends Component{
    constructor(props){
        super(props)
        this.state={
            observation: {},
            mutableFields:{
                heightNew:0,
                heightSnow:0,
                heightStorm:0,
                heightNewSwe:0,
                twentyCmTemp:0,
                surfaceTemp:0,
                formSize:0,
                precip:0,
                surfaceForm:"",
                skies:"",
                observer:""
            },
            error: <div/>
        }
        this.handleChange = this.handleChange.bind(this);
        this.updateObservation = this.updateObservation.bind(this);
        this.getObservationById = this.getObservationById.bind(this);
        this.getLatestObservation = this.getLatestObservation.bind(this);
        this.validateAndPostObs = this.validateAndPostObs.bind(this);
    }

    componentDidMount(){
        this.getLatestObservation();
    }

    handleChange(event) {
        const name = event.target.name
        const value = event.target.value
        this.setState(prevState => ({
            mutableFields: {
                ...prevState.mutableFields,
                [name]: value,
                observer: getName()
            }
        }))
        this.setState({error:<div/>})
    }

    getObservationById(dailySummaryKey){
        const {sourceId} = this.props.match.params
        this.setState({error:[]})
        var token = sessionStorage.getItem("Token");
        fetch(`https://wxloggerobservationapi.azurewebsites.net/v1/standardobservation?sourceId=${sourceId}&dailySummaryKey=${dailySummaryKey}`,{
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem getting observation summary')
            
            return response.json()
        })
        .then((json) => {
            this.setState({observation: json})
            this.setState({mutableFields: json})
        })
        .catch(error => {
            console.log(error)
            this.setState({error: [<p key={1000} className="error">No observation data for this date!</p>]})
        })
    }

    getLatestObservation(){
        const {sourceId} = this.props.match.params
        var token = sessionStorage.getItem("Token");
        fetch(`https://wxloggerobservationapi.azurewebsites.net/v1/lateststandardobservation?sourceId=${sourceId}`,{
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem getting daily observation summary')
            
            return response.json()
        })
        .then((json) => {
            this.setState({observation: json})
            this.setState({mutableFields: json})
        })
        .catch(error => console.log(error))
    }

    updateObservation(obj){
        var token = sessionStorage.getItem("Token");
        fetch(`https://wxloggerobservationapi.azurewebsites.net/v1/standardobservation`,{
            method: 'PUT',
            body: JSON.stringify(obj),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem updating observation summary')
            
            return response.json()
        })
        .then((json) => {
            this.setState({observation: json})
        })
        .catch(error => console.log(error))
    }

    validateFields(){
        var errors = []

        var validationFields = [
            {
                key: this.state.mutableFields.heightNew,
                displayName: "Height of new snow",
                exampleValue: "10.00",
                isNumber: true
            },
            {
                key: this.state.mutableFields.heightSnow,
                displayName: "Height of Snow",
                exampleValue: "100.00",
                isNumber: true
            },
            {
                key: this.state.mutableFields.heightStorm,
                displayName: "Height of Storm",
                exampleValue: "10.00",
                isNumber: true
            },
            {
                key: this.state.mutableFields.heightNewSwe,
                displayName: "New snow SWE",
                exampleValue: "1.00",
                isNumber: true
            },
            {
                key: this.state.mutableFields.twentyCmTemp,
                displayName: "Twenty CM Temp",
                exampleValue: "-7.25",
                isNumber: true
            },
            {
                key: this.state.mutableFields.surfaceTemp,
                displayName: "Surface Temp",
                exampleValue: "1.00",
                isNumber: true
            },
            {
                key: this.state.mutableFields.formSize,
                displayName: "Surface Form Size",
                exampleValue: "1.5",
                isNumber: true
            },
            {
                key: this.state.mutableFields.precip,
                displayName: "Precip",
                exampleValue: "1",
                isNumber: true
            },
            {
                key: this.state.mutableFields.surfaceForm,
                displayName: "Surface Form",
                isNumber: false
            },
            {
                key: this.state.mutableFields.skies,
                displayName: "Sky Cover",
                isNumber: false
            },
            {
                key: this.state.mutableFields.observer,
                displayName: "Observer",
                isNumber: false
            }
        ]
        
        validationFields.forEach(element => {
            if(element.key === undefined || element.key === ''){
                var presenceErrorString = `${element.displayName} cannot be blank` 
                errors.push(<p className="error">{presenceErrorString}</p>)
            }
            if(element.isNumber === true){ 
                if(!Number.isFinite(parseFloat(element.key))){
                    var numericErrorString = `${element.displayName} must be a valid value. Example Value: ${element.exampleValue}`
                    errors.push(<p className="error">{numericErrorString}</p>)
                }
            }
        })
        return errors
    }

    validateAndPostObs(){
        var errors = this.validateFields()

        if(errors.length > 0){
            this.setState({error: errors[0]})
        }else{
            const dto = {...this.state.observation, ...this.state.mutableFields}
            this.updateObservation(dto)
        }
    }

    render(){
        return(
            <div className="container">
                <Grid container
                    spacing={0}
                    alignItems="center"
                    justify="center"
                >
                    <Grid item
                        xs={12}
                    >
                        <Card>
                            <ObservationTable
                                observation={this.state.observation}
                            />
                            <Grid container
                                spacing={0}
                                alignItems="center"
                                justify="center"
                            >
                                <Grid item
                                    xs={4}
                                >
                                    <DatePickerComponent
                                        sourceId={this.props.match.params}
                                        update={this.getObservationById}
                                    />
                                </Grid>
                                <Grid item
                                    xs={8}
                                >
                                    <Grid container
                                        spacing={0}
                                        alignItems="center"
                                        justify="center"
                                    >
                                       <Grid item
                                            xs={4}
                                        >
                                            <div>
                                                <Tooltip title="Height of New Snow in Inches">
                                                    <TextField
                                                        required
                                                        label="New Snow In Past 24"
                                                        name="heightNew"
                                                        value={this.state.mutableFields.heightNew}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                    />
                                                </Tooltip>  
                                            </div>
                                            <div>
                                                <Tooltip title="Weight of New Snow in Inches of Water">
                                                    <TextField
                                                        required
                                                        label="New Snow Water Equiv"
                                                        name="heightNewSwe"
                                                        value={this.state.mutableFields.heightNewSwe}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                    />
                                                </Tooltip>  
                                            </div>
                                            <div>
                                                <Tooltip title="Surface Temperature in Celeius">
                                                    <TextField
                                                        required
                                                        label="Surface Temperature"
                                                        name="surfaceTemp"
                                                        value={this.state.mutableFields.surfaceTemp}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                    />
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                        <Grid item
                                            xs={4}
                                        >
                                            <div>
                                                <Tooltip title="Height of Storm Snow in Inches">
                                                    <TextField
                                                        required
                                                        label="Storm Total"
                                                        name="heightStorm"
                                                        value={this.state.mutableFields.heightStorm}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                    />  
                                                </Tooltip>
                                            </div>
                                            <div>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="age-native-simple">Snowfall Rate</InputLabel>
                                                    <Select
                                                    native
                                                    name="precip"
                                                    value={this.state.mutableFields.precip}
                                                    onChange={this.handleChange}
                                                    >
                                                        <option aria-label="" value="" />
                                                        <option value={0}>None</option>
                                                        <option value={1}>S1 - Light Snowfall</option>
                                                        <option value={2}>S2 - Moderate Snowfall</option>
                                                        <option value={5}>S5 - Heavy Snowfall</option>
                                                        <option value={10}>S10 - Very Heavy Snowfall</option>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div>
                                                <Tooltip title="Twenty Cm Temperature in Celeius">
                                                    <TextField
                                                        required
                                                        label="Twenty Cm Temp"
                                                        name="twentyCmTemp"
                                                        value={this.state.mutableFields.twentyCmTemp}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                    /> 
                                                </Tooltip>
                                                 
                                            </div>
                                        </Grid>
                                        <Grid item
                                            xs={4}
                                        >
                                            <div>
                                                <Tooltip title="Height of Snow in Inches">
                                                    <TextField
                                                        required
                                                        label="Height of Snow"
                                                        name="heightSnow"
                                                        value={this.state.mutableFields.heightSnow}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                    /> 
                                                </Tooltip>
                                            </div>
                                            <div>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="age-native-simple">Sky Condition</InputLabel>
                                                    <Select
                                                    native
                                                    name="skies"
                                                    value={this.state.mutableFields.skies}
                                                    onChange={this.handleChange}
                                                    >
                                                        <option aria-label="" value="" />
                                                        <option value="Clear">Clear</option>
                                                        <option value="Few">Few</option>
                                                        <option value="Scattered">Scatttered</option>
                                                        <option value="Broken">Broken</option>
                                                        <option value="Overcast">Overcast</option>
                                                        <option value="Obscured">Obscured</option>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div>
                                                <FormControl fullWidth>
                                                    <InputLabel htmlFor="age-native-simple">Surface Form</InputLabel>
                                                    <Select
                                                    native
                                                    name="surfaceForm"
                                                    value={this.state.mutableFields.surfaceForm}
                                                    onChange={this.handleChange}
                                                    >
                                                        <option aria-label="" value="" />
                                                        <option value="PP">Precipitation Particles</option>
                                                        <option value="DF">Decomposing Fragments</option>
                                                        <option value="RG">Rounded Grains</option>
                                                        <option value="FC">Faceted Crystals</option>
                                                        <option value="SH">Surface Hoar</option>
                                                        <option value="MF">Melt Forms</option>
                                                        <option value="IF">Ice Formation</option>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </Grid>
                                    </Grid>
                                    <Grid container
                                        spacing={0}
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item xs={4}>
                                            <div>
                                                <Tooltip title="Surface Form Size in mm">
                                                    <TextField
                                                        required
                                                        label="Surface Form Size"
                                                        name="formSize"
                                                        value={this.state.mutableFields.formSize}
                                                        onChange={this.handleChange}
                                                        fullWidth
                                                    />
                                                </Tooltip>
                                            </div>
                                        </Grid>
                                        <Grid item xs={4}>{this.state.error}</Grid>
                                        <Grid item xs={4}>
                                            <Button onClick={this.validateAndPostObs}>Save and Calculate</Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
                <Backdrop open={this.state.observation.heightNew === undefined ? true : false } style={backdropStyle}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }

}

export default SubmitObservationComponent