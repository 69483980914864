import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        fontSize: 20,
        margin: 20
    },
    line: {
        margin: 0
    },
    range: {
        color: '#0F508A'
    },
    label:{
        fontSize: 12,
        //color: '#0F508A'
    },
    shortForecast:{
        fontSize: 16
    }
}))

const HourlyTile = (props) => {

    var classes = useStyles();

    return(
        <div className={classes.root}>
            <h4 className={classes.line}>
                <span className={classes.range}>{props.data.range}</span>
            </h4>
            <h4 className={classes.line}>
                <span className={classes.label}>Temp:</span>
                <span> {props.data.temperature}°F </span>
                <span className={classes.label}>Wind Speed: </span>
                <span> {props.data.windSpeed} </span>
                <span className={classes.label}>Wind Dir: </span>
                <span> {props.data.windDirection}</span>
            </h4>
            <h4 className={classes.line}>
                <span className={classes.shortForecast}>{props.data.shortForecast}</span>
            </h4>
        </div>
    )
    
}

export default HourlyTile