import React from 'react'
import clsx from 'clsx';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {Link} from 'react-router-dom';

import LiveDataComponent from './LiveDataComponent'
import {getClientName} from '../../Utils'
import Auth from "../Login/Auth"

const FullAppBar = (props) => {
    return(
        <AppBar 
            position="fixed" 
            color="inherit"
            className={clsx(props.classes.appBar, {
                [props.classes.appBarShift]: props.open,
            })}>
            <Toolbar>
                <IconButton 
                    edge="start" 
                    color="inherit" aria-label="menu" 
                    onClick={()=>{props.handleDrawerOpen()}}
                    className={clsx(props.classes.menuButton, props.open && props.classes.hide)}
                > 
                    <MenuIcon />
                </IconButton>
                <Typography variant="h6" className={props.classes.title}>
                    {Auth.getAuth() ? getClientName(): "WxLogger"}
                </Typography>
                <LiveDataComponent/>
                <Button component={Link} to="/" color="inherit" onClick={()=>{props.handleSignout()}}>Sign Out</Button>
            </Toolbar>
        </AppBar>
    )
}

export default FullAppBar