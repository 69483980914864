import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

const CustomDialog = ({
    isOpen,
    handleClose,
    children
}) => (
    <div>
        <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          {children}
        </DialogContent>
      </Dialog>
    </div>
)

export default CustomDialog

