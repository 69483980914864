import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root:{
        fontSize: 20,
        maxWidth: '90%',
        margin: 10,
    },
    heading:{
        margin: 0,
        color: '#0F508A'
    },
    detail:{
        margin: 0
    }
}))

const ForecastTile = (props) =>{

    var classes = useStyles();

    return(
        <div className={classes.root}>
            <h4 className={classes.heading}>{props.data.name}</h4>
            <img src={props.data.icon} alt="whoops"/>
            <h5 className={classes.detail}> {props.data.shortForecast}</h5>
    {props.data.isDaytime? <p style={{color: "red"}}>High: {props.data.temperature}°F</p>: <p style={{color: "blue"}}>Low:  {props.data.temperature}°F</p>}
        </div>
    )
}

export default ForecastTile