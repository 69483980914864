import React,{Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import {dateTimeFormat, getMinTemp, getMaxTemp, getAverageTemp, getAverageHumidity, getAverageWindSpeed, getMaxWindSpeed} from '../../Utils';
import '../../StyleSheets/dashboard.css'
import DoubleLineDoubleAxisGraphComponent from '../Graphs/DoubleLineDoubleAxisGraphComponent';
import WindRoseGraphComponent from '../Graphs/WindRoseGraphComponent'
import DoubleLineGraphCompnent from '../Graphs/DoubleLineGraphComponent'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

const backdropStyle = {
    color: '#fff',
    zIndex: 9999
}

class TelemetryComponent extends Component{
    constructor(props){
        super(props);
        this.state = {
            hourlyReadings: [],
            windRose:[]
        }
        this.getHourlyReadings =  this.getHourlyReadings.bind(this);
        this.getWindRose = this.getWindRose.bind(this)
    }

    componentDidMount(){
        this.getHourlyReadings();
        this.getWindRose();
        
    }

    getHourlyReadings(){
        const { sourceId }  = this.props.match.params
        var token = sessionStorage.getItem("Token");
        fetch(`https://wxloggertelemeteryapi.azurewebsites.net/v1/hourlyreadings?sourceId=${sourceId}`,{
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem getting hourly readings')
            
            return response.json()
        })
        .then((json) => {
            this.setState({hourlyReadings: json})
        })
        .catch(error => console.log(error))
    }

    getWindRose(){
        const { sourceId }  = this.props.match.params
        var token = sessionStorage.getItem("Token");
        fetch(`https://wxloggertelemeteryapi.azurewebsites.net/v1/lastwindrose?sourceId=${sourceId}`,{
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem getting wind rose')
            
            return response.json()
        })
        .then((json) => {
            this.setState({windRose: json})
        })
        .catch(error => console.log(error))
    }
     
    render(){
        var prettyData = dateTimeFormat(this.state.hourlyReadings)
        return(
            <div className="dashCard">
                <Grid container
                    spacing={0}
                    alignItems="center"
                    justify="center"
                >
                    <Grid item
                        xs={11}
                    >
                        <Card>
                            <Grid container
                                spacing={0}
                                alignItems="center"
                                justify="center"
                            >
                                <Grid item
                                    xs={4}
                                >
                                    <h1 className="cardTitle">Telemetry Data</h1>
                                    <Grid container
                                        spacing={0}
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item xs={4}>
                                            <span className="dashLabel">Min Temp</span>
                                            <br/>
                                            <span className="dashValue">{getMinTemp(this.state.hourlyReadings)}°F</span>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <span className="dashLabel">Avg Temp</span>
                                            <br/>
                                            <span className="dashValue">{getAverageTemp(this.state.hourlyReadings)}°F</span>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <span className="dashLabel">Max Temp</span>
                                            <br/>
                                            <span className="dashValue">{getMaxTemp(this.state.hourlyReadings)}°F</span>
                                        </Grid>
                                    </Grid>
                                    <Grid container
                                        spacing={0}
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item xs={4}>
                                            <span className="dashLabel">Humidity</span>
                                            <br/>
                                            <span className="dashValue">{getAverageHumidity(this.state.hourlyReadings)}%</span>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <span className="dashLabel">Avg Wind Speed</span>
                                            <br/>
                                            <span className="dashValue">{getAverageWindSpeed(this.state.hourlyReadings)} mph</span>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <span className="dashLabel">Max Wind Speed</span>
                                            <br/>
                                            <span className="dashValue">{getMaxWindSpeed(this.state.hourlyReadings)} mph</span>
                                        </Grid>
                                    </Grid>
                                    <WindRoseGraphComponent
                                        windRose={this.state.windRose}
                                    />
                                </Grid>
                                <Grid item xs={8}>
                                    <div className="graph">
                                        <DoubleLineDoubleAxisGraphComponent
                                            data={prettyData}
                                            XAxis="prettyDate"
                                            lineOne="temp"
                                            lineTwo="baro"
                                            aspect={2.75/1}
                                            dot={false}
                                        />
                                    </div>
                                    <div className="graph">
                                        <DoubleLineGraphCompnent
                                            data={prettyData}
                                            XAxis="prettyDate"
                                            lineOne="maxWindSpeed"
                                            lineTwo="avgWindSpeed"
                                            aspect={2.75/1}
                                            dot={false}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
                <Backdrop open={this.state.windRose.n1 === undefined ? true : false} style={backdropStyle}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }
}

export default TelemetryComponent