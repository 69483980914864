import React, {Component} from 'react'
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend} from 'recharts'

class DoubleLineGraphComponent extends Component{
    render(){
        return(
            <div>
                <ResponsiveContainer width="95%" aspect={this.props.aspect} minWidth={250}>
                    <LineChart data={this.props.data}>
                        <Legend
                            verticalAlign="top"
                        />
                        <XAxis dataKey={this.props.XAxis} ticks={['auto']}/>
                        <YAxis/>
                        <CartesianGrid strokeDasharray="3 3"/>
                        <Tooltip/>
                        <Line type="monotone" dataKey={this.props.lineOne} stroke="#8884d8" activeDot={{r: 8}} dot={this.props.dot}/>
                        <Line type="monotone" dataKey={this.props.lineTwo} stroke="#82ca9d" dot={this.props.dot}/>
                    </LineChart>
                </ResponsiveContainer>
            </div>
        )
    }
}

export default DoubleLineGraphComponent