import React, {Component} from 'react';
import {Grid, Card, Tooltip, TextField, Button} from '@material-ui/core'
import SnowStakeTable from './Tables/SnowStakeTable';
import DatePickerComponent from '../DatePickers/DatePickerComponent';
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

const backdropStyle = {
    color: '#fff',
    zIndex: 9999
}

class SubmitSnowStakeComponent extends Component{
    constructor(props){
        super(props)
        this.state={
            snowStake: {},
            mutableFields:{
                heightNew: 0,
                heightSnow:0
            },
            error: <div/>
        }
        this.handleChange = this.handleChange.bind(this);
        this.getSnowStakebyId = this.getSnowStakebyId.bind(this);
        this.getLatestSnowStake = this.getLatestSnowStake.bind(this);
        this.updateSnowStake = this.updateSnowStake.bind(this);
        this.validateAndPostObs = this.validateAndPostObs.bind(this);
    }

    componentDidMount(){
        this.getLatestSnowStake();
    }

    handleChange(event) {
        const name = event.target.name
        const value = event.target.value
        this.setState(prevState => ({
            mutableFields: {
                ...prevState.mutableFields,
                [name]: value
            }
        }))
        this.setState({error:<div/>})
    }

    getLatestSnowStake(){
        const {sourceId} = this.props.match.params
        var token = sessionStorage.getItem("Token");
        fetch(`https://wxloggerobservationapi.azurewebsites.net/v1/latestsnowstake?sourceId=${sourceId}`,{
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem getting lastest snow stake summary')
            
            return response.json()
        })
        .then((json) => {
            this.setState({snowStake: json})
            this.setState({mutableFields: json})
        })
        .catch(error => console.log(error))
    }

    getSnowStakebyId(dailySummaryKey){
        const {sourceId} = this.props.match.params 
        this.setState({error:[]})
        var token = sessionStorage.getItem("Token");
        fetch(`https://wxloggerobservationapi.azurewebsites.net/v1/snowstake?sourceId=${sourceId}&dailySummaryKey=${dailySummaryKey}`,{
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem getting snow stake summary')
            
            return response.json()
        })
        .then((json) => {
            this.setState({snowStake: json})
            this.setState({mutableFields: json})
        })
        .catch(error => {
            console.log(error)
            this.setState({error: [<p key={1000} className="error">No snow stake data for this date!</p>]})
        })
    }

    updateSnowStake(obj){
        var token = sessionStorage.getItem("Token");
        fetch(`https://wxloggerobservationapi.azurewebsites.net/v1/snowstake`,{
            method: 'PUT',
            body: JSON.stringify(obj),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem updating snow stake')
            
            return response.json()
        })
        .then((json) => {
            this.setState({snowStake: json})
        })
        .catch(error => console.log(error))
    }
    
    validateFields(){
        var errors = []
        var validationFields = [
            {
                key: this.state.mutableFields.heightNew,
                displayName: "Height of new snow",
                exampleValue: "10.00",
                isNumber: true
            },
            {
                key: this.state.mutableFields.heightSnow,
                displayName: "Height of Snow",
                exampleValue: "100.00",
                isNumber: true
            }
        ]

        validationFields.forEach(element => {
            if(element.key === undefined || element.key === ''){
                var presenceErrorString = `${element.displayName} cannot be blank` 
                errors.push(<p className="error">{presenceErrorString}</p>)
            }
            if(element.isNumber === true){ 
                if(!Number.isFinite(parseFloat(element.key))){
                    var numericErrorString = `${element.displayName} must be a valid value. Example Value: ${element.exampleValue}`
                    errors.push(<p className="error">{numericErrorString}</p>)
                }
            }
        })
        return errors
    }

    validateAndPostObs(){
        var errors = this.validateFields()
        if(errors.length > 0){
            this.setState({error: errors[0]})
        }else{
            const dto = {...this.state.snowStake, ...this.state.mutableFields}
            this.updateSnowStake(dto)
        }
    }

    render(){
        return(
            <div className="container">
                <Grid container
                    spacing={0}
                    alignItems="center"
                    justify="center"
                >
                    <Grid item
                        xs={11}
                    >
                        <Card>
                            <SnowStakeTable
                                snowStake={this.state.snowStake}
                            />
                            <Grid container
                                spacing={0}
                                alignItems="center"
                                justify="center"
                            >
                                <Grid item
                                    xs={6}
                                >
                                    <DatePickerComponent
                                        sourceId={this.props.match.params}
                                        update={this.getSnowStakebyId}
                                    />
                                </Grid>
                                <Grid item
                                    xs={6}
                                >
                                    <div>
                                        <Tooltip title="Height of New Snow in Inches">
                                            <TextField
                                                required
                                                label="New Snow In Past 24"
                                                name="heightNew"
                                                value={this.state.mutableFields.heightNew}
                                                onChange={this.handleChange}
                                            />
                                        </Tooltip>  
                                    </div>
                                    <div>
                                        <Tooltip title="Height of Snow in Inches">
                                            <TextField
                                                required
                                                label="Height of Snow"
                                                name="heightSnow"
                                                value={this.state.mutableFields.heightSnow}
                                                onChange={this.handleChange}
                                            /> 
                                        </Tooltip>
                                    </div>
                                    <div>
                                        
                                        <Button size="large" onClick={this.validateAndPostObs}>Save and Calculate</Button>
                                        {this.state.error}
                                    </div>
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
                <Backdrop open={this.state.snowStake.heightNew === undefined ? true : false } style={backdropStyle}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }
}

export default SubmitSnowStakeComponent