import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import '../../StyleSheets/dashboard.css';
import Time from 'react-time-format';
import TinyLineChart from '../Graphs/TinyLineChart';
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

const backdropStyle = {
    color: '#fff',
    zIndex: 9999
}

class ObservationComponent extends Component{
    constructor(props){
        super(props);
        this.state={
            observation: {},
            observationData: []
        }
        this.getObservationData =  this.getObservationData.bind(this);
        this.getDailyObservation = this.getDailyObservation.bind(this);
    }

    componentDidMount(){
        this.getDailyObservation();
        this.getObservationData();
    }

    getDailyObservation(){
        const {sourceId} = this.props.match.params
        var token = sessionStorage.getItem("Token");
        fetch(`https://wxloggerobservationapi.azurewebsites.net/v1/lateststandardobservation?sourceId=${sourceId}`,{
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem getting daily observation summary')
            
            return response.json()
        })
        .then((json) => {
            this.setState({observation: json})
        })
        .catch(error => console.log(error))
    }

    getObservationData(){
        const {sourceId} = this.props.match.params
        var token = sessionStorage.getItem("Token");
        fetch(`https://wxloggerobservationapi.azurewebsites.net/v1/standardobservations?sourceId=${sourceId}`,{
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem getting observation data')
            
            return response.json()
        })
        .then((json) => {
            this.setState({observationData: json})
        })
        .catch(error => console.log(error))
    }

    render(){
        return(
            <div className="dashCard">
                <Grid container
                    spacing={0}
                    alignItems="center"
                    justify="center"
                >
                    <Grid item
                        xs={11}
                    >
                        <Card>
                            <Grid container
                                spacing={0}
                                alignItems="center"
                                justify="center"
                            >
                                <Grid item
                                    xs={6}
                                >
                                    <h1 className="cardTitle">Observation Data</h1>
                                    <Grid container
                                        spacing={0}
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item xs={3}>
                                            <span className="dashLabel">Height New 24hr</span>
                                            <br/>
                                            <span className="dashValue">{this.state.observation.heightNew}"</span>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <span className="dashLabel">Height New 48hr</span>
                                            <br/>
                                            <span className="dashValue">{this.state.observation.heightNew48}"</span>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <span className="dashLabel">Height New 72hr</span>
                                            <br/>
                                            <span className="dashValue">{this.state.observation.heightNew72}"</span>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <span className="dashLabel">Height Storm</span>
                                            <br/>
                                            <span className="dashValue">{this.state.observation.heightStorm}"</span>
                                        </Grid>
                                    </Grid>
                                    <Grid container
                                        spacing={0}
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item xs={3}>
                                            <span className="dashLabel">New Snow SWE</span>
                                            <br/>
                                            <span className="dashValue">{this.state.observation.heightNewSwe}" H2O</span>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <span className="dashLabel">New Snow Density</span>
                                            <br/>
                                            <span className="dashValue">{this.state.observation.newSnowDensity}%</span>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <span className="dashLabel">Height of Snow</span>
                                            <br/>
                                            <span className="dashValue">{this.state.observation.heightSnow}"</span>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <span className="dashLabel">Total Snowfall</span>
                                            <br/>
                                            <span className="dashValue">{this.state.observation.totalSnowfall}"</span>
                                        </Grid>
                                    </Grid>
                                    <Grid container
                                        spacing={0}
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item xs={3}>
                                            <span className="dashLabel">20cm Temp</span>
                                            <br/>
                                            <span className="dashValue">{this.state.observation.twentyCmTemp}°C</span>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <span className="dashLabel">Surface Temp</span>
                                            <br/>
                                            <span className="dashValue">{this.state.observation.surfaceTemp}°C</span>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <span className="dashLabel">Surface Form</span>
                                            <br/>
                                            <span className="dashValue">{this.state.observation.surfaceForm !== "" ? this.state.observation.surfaceForm : "n/o"}</span>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <span className="dashLabel">Form Size</span>
                                            <br/>
                                            <span className="dashValue">{this.state.observation.formSize}mm</span>
                                        </Grid>
                                    </Grid>
                                    <Grid container
                                        spacing={0}
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item xs={3}>
                                            <span className="dashLabel">Sky Cover</span>
                                            <br/>
                                            <span className="dashValue">{this.state.observation.skies !== "" ? this.state.observation.skies : "n/o" }</span>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <span className="dashLabel">Precip</span>
                                            <br/>
                                            <span className="dashValue">{this.state.observation.precip === 0 ? "None" : `S-${this.state.observation.precip}`}</span>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <span className="dashLabel">Observer</span>
                                            <br/>
                                            <span className="dashValue">{this.state.observation.observer !== "" ? this.state.observation.observer : "N/O"}</span>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <span className="dashLabel">Time</span>
                                            <br/>
                                            <span className="dashValue"><Time value={this.state.observation.updateDate} format="hh:mm"/></span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item
                                    xs={6}
                                >
                                    <p>Total Snowfall</p>
                                    <TinyLineChart
                                        data={this.state.observationData}
                                        property="totalSnowfall"
                                        aspect={4/1}
                                        lineColor="#82ca9d"
                                    />
                                    <p>Height of Snow</p>
                                    <TinyLineChart
                                        data={this.state.observationData}
                                        property="heightSnow"
                                        aspect={4/1}
                                        lineColor="#8884d8"
                                    />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
                <Backdrop open={this.state.observationData[0] === undefined ? true : false } style={backdropStyle}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }
}

export default ObservationComponent