import React, {Component} from 'react'
import '../../StyleSheets/login.css'
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid'
import { Typography, Button } from '@material-ui/core';

class ResetComponent extends Component{
    constructor(props){
        super(props);
        this.state = {
            password: ""
        }
        
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }

    handleChange(event){
        event.preventDefault();
        const name = event.target.name
        const value = event.target.value
        this.setState({[name]: value})
    }

    updatePassword(password){
        
        const { payload }  = this.props.match.params
        const decodedPayload = atob(payload)
        const payloadArray = decodedPayload.split(":")
        const email = payloadArray[0]
        const resetCode = payloadArray[1]

        fetch(`https://wxloggerauthapi.azurewebsites.net/user/passwordreset?resetcode=${resetCode}`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + Buffer.from(email + ":" + password).toString('base64')
            }
        })
        .then(response =>{
            if(!response.ok){
                throw new Error('unauthorized')
            }
                
            return response.json()
        })
        .then((json) => {
            if(!json)
                throw new Error('unauthorized')
            else{
                this.setState({success: true})
                this.timer = setInterval(() => window.location.href = "/", 1000)
            }
                
        })
        .catch(error => {
            this.setState({errorMsg: "Invalid Link"})
            this.setState({error: true})
            this.timer = setInterval(() => this.setState({error: false}), 5000)
        }) 
    }

    displayError(){
        if(this.state.error === true){
            return <div className="loginError">{this.state.errorMsg}</div>
        }else{
            return<div/>
        }
    }

    validatePassword(password){
        const regEx = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[.!@#\$%\^&\*])(?=.{8,})"); /* eslint-disable-line */
        const isOk = regEx.test(password)

        if(!isOk)
            return false
    }

    handleSubmit(){
        const password = this.state.password
        const validPassword  = this.validatePassword(password)
        if(validPassword === false){
            this.setState({errorMsg: "Invalid Password"})
            this.setState({error: true})
            this.timer = setInterval(() => this.setState({error: false}), 10000)
        }else{
            this.updatePassword(password)
        }
    }

    handleKeyPress(event){
        if(event.key === "Enter"){
            event.preventDefault();
            this.handleSubmit()
        }
    }

    displaySuccess(){
        if(this.state.success === true){
            return  (
                <Typography variant="h5" align="center">
                    Your password was successfully updated. You will be redirected.
                </Typography>
            )
        }else{
            return  (
                <div>
                    <form>
                        <div>
                            <TextField
                                label="Password"
                                type="password"
                                name="password" 
                                onChange={this.handleChange} 
                                onKeyPress={this.handleKeyPress}
                                fullWidth
                                autoComplete="true"
                                helperText="passwords must be between 8 - 32 charaters, have upper and lower case, contain a number, and contain a special charater"
                            />
                        </div>
                    </form>
                    <div className="submitButton">
                        <Button
                            type="submit" 
                            fullWidth
                            onClick={this.handleSubmit}
                            size="large"
                            variant="outlined"
                        >
                            Submit
                        </Button>
                    </div>
                    {this.displayError()}
                </div>
            )
                
        }
    }
    render(){
        return(
            <div>
                <Grid
                    container
                    spacing={5}
                    alignItems="center"
                    justify="center"
                >
                    <Grid item xs={4}>
                        <Card className="loginCard">
                            <CardContent>
                                <Typography 
                                    variant="h4" 
                                    align="center"
                                >
                                    <b>Update Password</b>
                                </Typography>
                                {this.displaySuccess()}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>  
                
            </div>
        )
    }
}

export default ResetComponent;
