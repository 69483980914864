export function dateformat(dataset){
    for(var i = 0; i < dataset.length; i++){
        var eventDate = dataset[i].createDate.split('T')
        var YearMonthDay = eventDate[0].split('-')
        dataset[i].prettyDate = `${YearMonthDay[1]}/${YearMonthDay[2]}/${YearMonthDay[0]}`
    }
    return dataset
}

export function dateTimeFormat(dataset){
    for(var i = 0; i < dataset.length; i++){
        var eventDate = dataset[i].createDate.split('T')
        var YearMonthDay = eventDate[0].split('-')
        var timeStamp = eventDate[1].split('.')
        dataset[i].prettyDate = `${timeStamp[0]} ${YearMonthDay[1]}/${YearMonthDay[2]}/${YearMonthDay[0]}`
    }
    return dataset
}

export function formatTime(hour){
    if(hour > 12){
        var intTime = hour - 12 
        return `${intTime}:00pm`
    }else if(hour === 12){
        return '12:00pm'
    }else if(hour === 0){
        return '12:00am'
    }else{
        return `${hour}:00am`
    }
}

function  isolateTemps(array){
    var ints = []
    array.forEach(item =>{
        ints.push(item.temp)
    })
    return ints
}

export function getMinTemp(array){
    if(array === [])
        return
    var temps = isolateTemps(array)
    return Math.min(...temps)
}

export function getAverageTemp(array){
    if(array === [])
        return
    var temps = isolateTemps(array)
    let sum = temps.reduce(function(acc, val) { return acc + val; }, 0)
    return (sum/temps.length).toFixed(1)
}

export function getMaxTemp(array){
    if(array === [])
        return
    var temps = isolateTemps(array)
    return Math.max(...temps)
}

export function getAverageHumidity(array){
    if(array === [])
        return
    var humidities = []
    array.forEach(item =>{
        humidities.push(item.avgHumidity)
    })
    let sum = humidities.reduce(function(acc, val) { return acc + val; }, 0)
    return (sum/humidities.length).toFixed(1)
}

export function getAverageWindSpeed(array){
    if(array === [])
        return
    var windspeeds = []
    array.forEach(item =>{
        windspeeds.push(item.avgWindSpeed)
    })
    let sum = windspeeds.reduce(function(acc, val) { return acc + val; }, 0)
    return (sum/windspeeds.length).toFixed(1)
}

export function getMaxWindSpeed(array){
    if(array === [])
        return
    var windspeeds = []
    array.forEach(item =>{
        windspeeds.push(item.maxWindSpeed)
    })
    return Math.max(...windspeeds)
}

export function capString(string){
    return string.replace(/^\w/, c => c.toUpperCase());
}

export function lowerString(string){
    return string.replace(/^\w/, c => c.toLowerCase());
}

export function getClaims(){
    var token = sessionStorage.getItem("Token")
    var tokenArray = token.split(".")
    var encodedClaims = tokenArray[1]
    var decodedClaims = atob(encodedClaims)
    var claims = JSON.parse(decodedClaims)
    return claims
}

export function getRole(){
    var claims = getClaims()
    return claims.role
}

export function getName(){
    var claims = getClaims()
    var firstInitial = claims.firstname[0]
    return ` ${firstInitial}. ${claims.lastname}`
}

export function getClientName(){
    var claims = getClaims();
    return claims.clientname
}

export function getWindDirection(windBearing){
    if(windBearing < 22 ){
        return {abbr:'N',verbose:"North"}
    }else if(windBearing < 67){
        return {abbr:'NE',verbose:"Northeast"}
    }else if(windBearing < 112){
        return {abbr:'E',verbose:"East"}
    }else if(windBearing < 157){
        return {abbr:'SE',verbose:"Southeast"}
    }else if(windBearing < 212){
        return {abbr:'S',verbose:"South"}
    }else if(windBearing < 247){
        return {abbr:'SW',verbose:"Southwest"}
    }else if(windBearing < 292){
        return {abbr:'W',verbose:"West"}
    }else if(windBearing < 337){
        return {abbr:'NW',verbose:"Northwest"}
    }else{
        return {abbr:'N',verbose:"North"}
    }
}

export function getDataSource(sourceId){
    let datasourcesString = sessionStorage.getItem("datasources")
    let datasources = JSON.parse(datasourcesString)
    return datasources.filter(x => x.sourceId === sourceId)[0]
}
