import React, {Component} from 'react'
import '../../StyleSheets/Admin/adduser.css'
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';

class AddUser extends Component{
    constructor(props){
        super(props);
        this.state={
            email: "",
            firstName: "",
            lastName: '',
            userRole: ""
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(event) {
        event.preventDefault();
        const name = event.target.name
        const value = event.target.value
        this.setState({[name]: value})
    }

    handleSubmit(){
        const token = sessionStorage.getItem("Token");

        var user = { 
            userId:0, 
            clientId:0,
            email: this.state.email, 
            password: "", 
            isActive: true,
            userRole: this.state.userRole,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            refreshToken: "",
            resetCode: 0
        }

        fetch(`https://wxloggerauthapi.azurewebsites.net/user/add`,{
            method: 'POST',
            body: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response =>{
            if(!response.ok){
                throw new Error('unauthorized')
            }
                
            return response.json()
        })
        .then((json) => {
            console.log("user added")
            this.props.updateUsers()
            this.props.handleClose()
        })
        .catch(error => {
            console.log("something failed")
        })
    }

    render(){
        return(
            <div>
                <h2>Add New User</h2>
                <form>
                    <div>
                        <TextField
                            required
                            id="filled-required"
                            label="Email Address"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                        />  
                    </div>
                    <div>
                        <TextField
                            required
                            id="filled-required"
                            label="First Name"
                            name="firstName"
                            value={this.state.firstName}
                            onChange={this.handleChange}
                        />  
                    </div>
                    <div>
                        <TextField
                            required
                            id="filled-required"
                            label="Last Name"
                            name="lastName"
                            value={this.state.lastName}
                            onChange={this.handleChange}
                        />  
                    </div>
                    <div>
                        <FormControl>
                            <InputLabel htmlFor="age-native-simple">User Role  </InputLabel>
                            <Select
                            native
                            name="userRole"
                            value={this.state.userRole}
                            onChange={this.handleChange}
                            >
                                <option aria-label="None" value="" />
                                <option value='admin'>Admin</option>
                                <option value='observer'>Observer</option>
                                <option value='user'>User</option>
                            </Select>
                        </FormControl>
                    </div>
                </form>
                <div className="addButtons">
                    <DialogActions>
                        <Button onClick={this.handleSubmit}>
                            Add User
                        </Button>
                        <Button onClick={this.props.handleClose} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </div>
            </div>
            
        )
    }
} 

export default AddUser