import React from 'react'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';


const EmptyAppBar = (props) => {
    return (
    <div>
        <AppBar position="fixed" color="inherit">
            <Toolbar>
                <Typography variant="h6" className={props.title}>
                    Telemetry Stream
                </Typography>
            </Toolbar>
        </AppBar>
    </div>
  )
}

export default EmptyAppBar