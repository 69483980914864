import React, {Component} from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import '../../StyleSheets/datepicker.css'

class DatePickerComponent extends Component{
    constructor(props){
        super(props)
        this.state={
            selectedDay: null,
            error: <div/>
        }
        this.handleDayClick = this.handleDayClick.bind(this);
        this.getDailySummaryKey = this.getDailySummaryKey.bind(this);
    }

    handleDayClick(day) {
        this.setState({error: <div/>})
        this.setState({
          selectedDay: day,
        });
        var date = day.toLocaleDateString('en-US')
        this.getDailySummaryKey(date);
    }
    
    getDailySummaryKey(date){
        var token = sessionStorage.getItem("Token")
        fetch(`https://wxloggerconfigurationapi.azurewebsites.net/v1/dailysummary?date=${date}`,{
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem getting daily summary key')
            
            return response.json()
        })
        .then((json) => {
            var dailySummaryKey = json.dailySummaryKey
            this.props.update(dailySummaryKey, this.props.sourceId)
        })
        .catch(error => {
            console.log(error)
            this.setState({error: <p className="error">No data for specified date!</p>})
        })
    }

    render(){
        return(
            <div>
                {this.state.error}
                <DayPicker
                    selectedDays={this.state.selectedDay}
                    onDayClick={this.handleDayClick}
                />
            </div>
        )
    }
}

export default DatePickerComponent
