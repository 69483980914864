import React, {Component} from 'react'
import {Grid, Card, Button} from '@material-ui/core'
import DateRangePickerComponent from '../DatePickers/DateRangePickerComponent'
import ExistingReportComponent from './ExistingReportComponent';
import NewReportComponent from './NewReportComponent';

const header = {
    height: 100
}

class ReportCompnent extends Component{
    constructor(props){
        super(props);
        this.state = {
            reportData: [],
            startDate: undefined,
            endDate: undefined,
            newReport: false,
        }
        this.getReportData = this.getReportData.bind(this)
        this.getKeys = this.getKeys.bind(this)
    }

    getReportData(startDate, endDate){
        var token = sessionStorage.getItem("Token")
        var url = `https://wxloggerreportapi.azurewebsites.net/v1/dailyreportdata?startDate=${startDate}&endDate=${endDate}`
        fetch(url,{
                method: 'Get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response =>{
                if(!response.ok)
                    throw new Error('Problem getting report data')
                
                return response.json()
            })
            .then((json) => {
                this.setState({reportData: json})
            })
            .catch(error => {
                console.log(error)
        })
    }

    getKeys(startDate, endDate){
        this.setState({startDate: startDate})
        this.setState({endDate: endDate})
        this.getReportData(startDate, endDate)
    }

    handleClick(arg){
        this.setState({newReport: arg})
    }

    render(){
        return(
            <div className="dashCard">
                <Grid container
                    spacing={0}
                    alignItems="center"
                    justify="center"
                >
                    <Grid item
                        xs={12}
                    >
                        <Card>
                            <Grid container
                                spacing={0}
                                alignItems="center"
                                justify="center"
                                style={header}
                            >
                                <Grid item
                                    xs={4}
                                >
                                    <h3 className="cardTitle">Reports</h3>
                                </Grid>
                                <Grid item
                                    xs={4}
                                >
                                    <Button onClick={()=>{this.handleClick(true)}}>New Report</Button>
                                </Grid>
                                <Grid item
                                    xs={4}
                                >
                                    <Button onClick={()=>{this.handleClick(false)}}>Existing Report</Button>
                                </Grid>
                            </Grid>
                            <Grid container
                                spacing={0}
                                alignItems="center"
                                justify="center"
                            >
                                <Grid item
                                    xs={4}
                                >
                                    <DateRangePickerComponent
                                        update={this.getKeys}
                                    />
                                </Grid>
                                <Grid item
                                    xs={8}
                                >
                                    {!this.state.newReport ? <ExistingReportComponent data={this.state.reportData}/> : <NewReportComponent data={this.state.reportData}/> }
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default ReportCompnent