import React, {Component} from 'react'
import {VictoryChart, VictoryPolarAxis, VictoryStack, VictoryBar, VictoryTheme} from 'victory'

class WindRoseGraphComponent extends Component{
    render(){
        const wind = [
            {
                low: this.props.windRose.e1,
                moderate: this.props.windRose.e2,
                considerable: this.props.windRose.e3,
                high: this.props.windRose.e4,
                severe: this.props.windRose.e5,
                extreme: this.props.windRose.e6,
                windBearing: 0
            },{
                low: this.props.windRose.nE1,
                moderate: this.props.windRose.nE2,
                considerable: this.props.windRose.nE3,
                high: this.props.windRose.nE4,
                severe: this.props.windRose.nE5,
                extreme: this.props.windRose.nE6,
                windBearing: 45
             },{
                low: this.props.windRose.n1,
                moderate: this.props.windRose.n2,
                considerable: this.props.windRose.n3,
                high: this.props.windRose.n4,
                severe: this.props.windRose.n5,
                extreme: this.props.windRose.n6,
                windBearing: 90
            },{
                low: this.props.windRose.nW1,
                moderate: this.props.windRose.nW2,
                considerable: this.props.windRose.nW3,
                high: this.props.windRose.nW4,
                severe: this.props.windRose.nW5,
                extreme: this.props.windRose.nW6,
                windBearing: 135
            },{
                low: this.props.windRose.w1,
                moderate: this.props.windRose.w2, 
                considerable: this.props.windRose.w3,
                high: this.props.windRose.w4,
                severe: this.props.windRose.w5,
                extreme: this.props.windRose.w6,
                windBearing: 180
            },{
                low: this.props.windRose.sW1,
                moderate: this.props.windRose.sW2,
                considerable: this.props.windRose.sW3,
                high: this.props.windRose.sW4,
                severe: this.props.windRose.sW5,
                extreme: this.props.windRose.sW6,
                windBearing: 225
            },{
                low: this.props.windRose.s1,
                moderate: this.props.windRose.s2,
                considerable: this.props.windRose.s3,
                high: this.props.windRose.s4,
                severe: this.props.windRose.s5,
                extreme: this.props.windRose.s6,
                windBearing: 270 
            },{
                low: this.props.windRose.sE1,
                moderate: this.props.windRose.sE2,
                considerable: this.props.windRose.sE3,
                high: this.props.windRose.sE4,
                severe: this.props.windRose.sE5,
                extreme: this.props.windRose.sE6,
                windBearing: 315
            },
          ];
        return(
        <div> 
            <VictoryChart 
                polar
                height={250}
                //width={200}
                padding={15}
                theme={VictoryTheme.material}
                
            >
                <VictoryPolarAxis
                labelPlacement="vertical"
                tickValues={[0, 45, 90, 135, 180, 225, 270, 315]}
                tickFormat={["E","NE", "N", "NW", "W", "SW", "S", "SE"]}
                />
                <VictoryStack>
                    <VictoryBar 
                        name="bar-1"
                        style={{ data: { fill: "92D135", width: 25 } }}
                        data={wind}
                        x="windBearing"
                        y="low"
                    />
                    <VictoryBar
                        name="bar-2"
                        style={{ data: { fill: "FFFF27", width: 25 } }}
                        data={wind}
                        x="windBearing"
                        y="moderate"
                    />
                    <VictoryBar
                        name="bar-3"
                        style={{ data: { fill: "FFA200", width: 25 } }}
                        data={wind}
                        x="windBearing"
                        y="considerable"
                    />
                    <VictoryBar
                        name="bar-4"
                        style={{ data: { fill: "FF7700", width: 25 } }}
                        data={wind}
                        x="windBearing"
                        y="high"
                    />
                    <VictoryBar
                        name="bar-5"
                        style={{ data: { fill: "FF0000", width: 25 } }}
                        data={wind}
                        x="windBearing"
                        y="severe"
                    />
                    <VictoryBar
                        name="bar-6"
                        style={{ data: { fill: "black", width: 25 } }}
                        data={wind}
                        x="windBearing"
                        y="extreme"
                    />
                </VictoryStack>
            </VictoryChart>
        </div>      
        )
    }
}

export default WindRoseGraphComponent