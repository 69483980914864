import React, {Component} from 'react'
import '../../StyleSheets/login.css'
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid'
import { Typography, Button } from '@material-ui/core';

class ResetRequestComponent extends Component{
    constructor(props){
        super(props);
        this.state ={
            email: ''
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleChange(event){
        event.preventDefault();
        const name = event.target.name
        const value = event.target.value
        this.setState({[name]: value})
    }

    handleSubmit(){
        fetch(`https://wxloggerauthapi.azurewebsites.net/user/resetrequest?emailaddress=${this.state.email}`,{
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            }
        })
        .then(response =>{
            if(!response.ok){
                throw new Error('unauthorized')
            }
                
            return response.json()
        })
        .then((json) => {
            if(!json)
                throw new Error('unauthorized')
            else{
                this.setState({success: true})
                this.timer = setInterval(() => window.location.href = "/", 1000)
            }
                
        })
        .catch(error => {
            this.setState({error: true})
            this.timer = setInterval(() => this.setState({error: false}), 5000)
        }) 
    }

    displayError(){
        if(this.state.error === true){
            return <div className="loginError"><p>Invaild Email Address</p></div>
        }else{
            return<div/>
        }
    }

    displaySuccess(){
        if(this.state.success === true){
            return(
                <Typography variant="h5" align="center">
                    Your password reset request was successful. Please check your Email.
                </Typography>
            )            
        }else{
            return  (
                <div>
                    <form>
                        <div>
                            <TextField
                                label="Email Address"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                fullWidth
                            />  
                        </div>
                    </form>
                    <div className="submitButton">
                        <Button
                            type="submit" 
                            fullWidth
                            onClick={this.handleSubmit}
                            size="large"
                            variant="outlined"
                        >
                            Submit
                        </Button>
                    </div>    
                    {this.displayError()}
                </div>
            )
        }
    }

    render(){
        return(
            <div>
                <Grid
                    container
                    spacing={5}
                    alignItems="center"
                    justify="center"
                >
                    <Grid item xs={4}>
                        <Card className="loginCard">
                            <CardContent>
                                <Typography 
                                    variant="h4" 
                                    align="center"
                                >
                                    <b>Reset Password</b>
                                </Typography>
                                {this.displaySuccess()}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>  
                
            </div>
        )
    }
}

export default ResetRequestComponent