import React, {Component} from 'react'
import '../../StyleSheets/reports.css'
import TableExportComponent from './TableExportComponent'
import {FormControl, InputLabel, Select} from '@material-ui/core'

class ExistingReportComponent extends Component{
    constructor(props){
        super(props)
        this.state = {
            existingReports: [],
            reportParameters: []
        }
        this.getExistingReports = this.getExistingReports.bind(this);
        this.getReportParameters = this.getReportParameters.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount(){
        this.getExistingReports();
    }

    handleChange(event){
        this.getReportParameters(event.target.value)
    }

    getExistingReports(){
        var token = sessionStorage.getItem("Token")
        fetch(`https://wxloggerreportapi.azurewebsites.net/v1/reports`,{
                method: 'Get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response =>{
                if(!response.ok)
                    throw new Error('Problem getting existing reports')
                
                return response.json()
            })
            .then((json) => {
                this.setState({existingReports: json})
            })
            .catch(error => {
                console.log(error)
        })
    }

    getReportParameters(reportId){
        var token = sessionStorage.getItem("Token")
        fetch(`https://wxloggerreportapi.azurewebsites.net/v1/reportvalues?reportId=${reportId}`,{
                method: 'Get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response =>{
                if(!response.ok)
                    throw new Error('Problem getting existing Report Parameters')
                
                return response.json()
            })
            .then((json) => {
                this.setState({reportParameters: json})
            })
            .catch(error => {
                console.log(error)
        })
    }

    render(){
        var selectValues = []
        this.state.existingReports.forEach((report) =>{
            selectValues.push(<option key={report.reportId} value={report.reportId}>{report.reportName}</option>)
        })
        return(
            <div>
                <form>
                    <FormControl>
                        <InputLabel>Reports</InputLabel>
                        <Select
                            native
                            name="exisitngReports"
                            onChange={this.handleChange}
                        >
                            <option aria-label="Select a Report" value="" />
                            {selectValues}
                        </Select>
                    </FormControl>
                </form>
                <div>
                    <TableExportComponent
                        params={this.state.reportParameters}
                        data={this.props.data}
                    />
                </div>
            </div>
        )
    }
}

export default ExistingReportComponent