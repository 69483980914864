import React from 'react'
import { TableContainer, Table,  TableHead, TableBody, TableRow, TableCell } from "@material-ui/core"

const ObservationTable = (props) => {
    return(
        <div>
            <TableContainer>
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Height New 24</TableCell>
                            <TableCell>Height New 48</TableCell>
                            <TableCell>Height New 72</TableCell>
                            <TableCell>Storm Total</TableCell>
                            <TableCell>Height Snow</TableCell>
                            <TableCell>Total Snowfall</TableCell>
                            <TableCell>Sky Condition</TableCell>
                            <TableCell>Snowfall Rate</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">{props.observation.heightNew}"</TableCell>
                            <TableCell>{props.observation.heightNew48}"</TableCell>
                            <TableCell>{props.observation.heightNew72}"</TableCell>
                            <TableCell>{props.observation.heightStorm}"</TableCell>
                            <TableCell>{props.observation.heightSnow}"</TableCell>
                            <TableCell>{props.observation.totalSnowfall}"</TableCell>
                            <TableCell>{props.observation.skies !== "" ? props.observation.skies : "N/O" }</TableCell>
                            <TableCell>{props.observation.precip === 0 ? "None" : `S-${props.observation.precip}`}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Table stickyHeader aria-label="sticky table"> 
                    <TableHead>
                        <TableRow>
                            <TableCell>New Snow Water Equiv</TableCell>
                            <TableCell>New Snow Density</TableCell>
                            <TableCell>Twenty Cm Temperature</TableCell>
                            <TableCell>Surface Temperateure</TableCell>
                            <TableCell>Surface Form</TableCell>
                            <TableCell>Surface Form Size</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell component="th" scope="row">{props.observation.heightNewSwe}"</TableCell>
                            <TableCell>{props.observation.newSnowDensity}%</TableCell>
                            <TableCell>{props.observation.twentyCmTemp}°C</TableCell>
                            <TableCell>{props.observation.surfaceTemp}°C</TableCell>
                            <TableCell>{props.observation.surfaceForm !== "" ? props.observation.surfaceForm : "N/O"}</TableCell>
                            <TableCell>{props.observation.formSize}mm</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default ObservationTable