import React, {Component} from 'react'
import { GridColumn, Grid as KendoGrid} from '@progress/kendo-react-grid';
import { ExcelExport} from '@progress/kendo-react-excel-export';
import {Button} from '@material-ui/core'
import '../../StyleSheets/reports.css'

class TableExportComponent extends Component{
    _export 

    export = () => {
        this._export.save();
    }

    render(){
        var data = []
        var columns = []
        this.props.params.forEach(param =>{
            columns.push(<GridColumn key={param.reportValueId} field={param.columnName} title={param.alias} width={150}/>)
        })
        if(columns.length > 0){
            data = this.props.data
        }
        return(
            <div className="reportWrapper">
                    <div className="exportTable">
                        <ExcelExport
                            data={data}
                            ref={(exporter) => { this._export = exporter; }}
                            fileName="WeatherDataExport.xlsx"
                        >
                            <KendoGrid data={data}>
                                {columns}
                            </KendoGrid>
                        </ExcelExport>
                    </div>
                <Button onClick={this.export}>Export to Excel File</Button>
            </div>
        )
    }
}

export default TableExportComponent