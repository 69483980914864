import React, {Component} from 'react'

class NewReportComponent extends Component{
    constructor(props){
        super(props)
        this.state = {
            availableColumns: []
        }
        this.getAvailableColumns = this.getAvailableColumns.bind(this);
    }

    componentDidMount(){
        this.getAvailableColumns();
    }
    
    getAvailableColumns(){
        var token = sessionStorage.getItem("Token")
        fetch(`https://wxloggerreportapi.azurewebsites.net/v1/dailyparameters`,{
                method: 'Get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response =>{
                if(!response.ok)
                    throw new Error('Problem getting available columns')
                
                return response.json()
            })
            .then((json) => {
                this.setState({availableColumns: json})
            })
            .catch(error => {
                console.log(error)
        })
    }

    render(){
        return(
            <div>
                new report component works
            </div>
        )
    }
}

export default NewReportComponent