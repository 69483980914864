import React from 'react';
import { TableContainer, Table,  TableHead, TableBody, TableRow, TableCell} from "@material-ui/core"

const MountainStatsTable = (props) => {
    return(
        <TableContainer>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        <TableCell>Lifts Open</TableCell>
                        <TableCell>Runs Open</TableCell>
                        <TableCell>Acreage</TableCell>
                        <TableCell>Surface Conditions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell component="th" scope="row">{props.mountainStats.liftsOpen}</TableCell>
                        <TableCell>{props.mountainStats.runsOpen}</TableCell>
                        <TableCell>{props.mountainStats.acreage}</TableCell>
                        <TableCell>{props.mountainStats.surfaceConditions}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default MountainStatsTable 