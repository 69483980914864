import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import '../../../StyleSheets/dashboard.css';
import {getDataSource, formatTime} from '../../../Utils';
import HourlyTile from './HourlyTile';
import ForecastTile from './ForecastTile'
import ForecastDetailTile from './ForecastDetailTile'

class ForecastComponent extends React.Component{
    constructor(props){
        super(props);
        this.state={
            forecast: {
                periods: []
            },
            hourly: {
                periods: []
            }
        }
        this.getForecast = this.getForecast.bind(this)
    }

    componentDidMount(){
        this.getForecast()
    }

    getForecast(){
        var sourceId = this.props.match.params.sourceId 
        sourceId = parseInt(sourceId)
        var dataSource = getDataSource(sourceId);
        var hourlyUrl = ''
        var forecastUrl = ''

        fetch(`https://api.weather.gov/points/`+ dataSource.gpsCoordinates,{
            method: 'Get',
            headers: {
                'User-Agent': '(telemetrystream.com, info@telemetrystream.com)'
            }
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem getting forcast config')

            return response.json()
        })
        .then(json => {
            forecastUrl = json.properties.forecast
            hourlyUrl = json.properties.forecastHourly
            
            return fetch(forecastUrl,{
                method: 'Get',
                headers: {
                    'User-Agent': '(telemetrystream.com, info@telemetrystream.com)'
                }
            })
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem getting forcast data')

            return response.json()
        })
        .then(json => {
            let properties = json.properties
            properties.periods = properties.periods.slice(0,8)
            this.setState({forecast: properties})
            
            return fetch(hourlyUrl,{
                method: 'Get',
                headers: {
                    'User-Agent': '(telemetrystream.com, info@telemetrystream.com)'
                }
            })
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem getting hourly forcast data')

            return response.json()
        })
        .then(json => {
            var properties = json.properties
            properties.periods = properties.periods.slice(1,10)
            properties.periods.forEach(period => {
                var startDate = new Date(period.startTime)
                var endDate = new Date(period.endTime)
                var startTime = startDate.getHours()
                var endTime = endDate.getHours()
                period.range = `${formatTime(startTime)} - ${formatTime(endTime)}`
            })
            this.setState({hourly: properties})
        })
        .catch( error => {
            console.log(error)
        })
    }


    render(){   
        return(
            <div className="dashCard">
                <Grid container
                    spacing={0}
                    alignItems="center"
                    justify="center"
                >
                    <Grid item
                        xs={11}
                    >
                        <Card>
                            <Grid container
                                spacing={0}
                            >
                                <Grid item xs={4}>
                                <h1 className="cardTitle">Forecast</h1>
                                {this.state.hourly.periods.map(period => (
                                    <HourlyTile data={period} key={period.number}/>
                                ))}
                                </Grid>
                                <Grid item xs={8}>
                                    <Grid container
                                        spacing={0}
                                        //alignItems="center"
                                        justify="center"
                                    >
                                        {this.state.forecast.periods.map(period => (
                                            <Grid item xs={3} key={period.number}>
                                                <ForecastTile data={period}/>
                                            </Grid>
                                        ))}
                                    </Grid>
                                    {this.state.forecast.periods.map(period =>(
                                        <ForecastDetailTile data={period} key={period.number}/>
                                    ))}
                                </Grid>
                            </Grid>
                        </Card> 
                    </Grid>
                </Grid>
            </div>
        )
    }
}

export default ForecastComponent