  import {getRole} from '../../Utils';

  const Auth = { 
    getAuth() {
        var token  = sessionStorage.getItem("Token") 
        if(token) 
            return true
    },

    getAdminAuth(){
        var token  = sessionStorage.getItem("Token")
        if(token){
          var role = getRole(token)
          if(role === "super" || role === "admin")
            return true
        }
    },

    getElevatedAuth(){
      var token  = sessionStorage.getItem("Token")
      if(token){
        var role = getRole(token)
        if(role === "super" || role === "admin" || role === "observer")
          return true
      }
    },

    signout(){
        sessionStorage.clear()
    }
  };

  export default Auth;