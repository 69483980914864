import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import {Add, Edit, Delete} from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import CustomDialog from '../Display/CustomDialog';
import AddUser from '../Admin/AddUser';
import DeleteUser from '../Admin/DeleteUser';
import EditUser from '../Admin/EditUser';
import '../../StyleSheets/Admin/admin.css'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

const backdropStyle = {
    color: '#fff',
    zIndex: 9999
}

class UserIndex extends Component{
    constructor(props){
        super(props);
        this.state = {
            users:[],
            showDialog: false,
            dialogComponent: <div/>
        }

        this.getUsers = this.getUsers.bind(this)
        this.handleDialogOpen = this.handleDialogOpen.bind(this)
        this.handleDialogClose = this.handleDialogClose.bind(this)
        this.addPropmt = this.addPrompt.bind(this)
        this.editPrompt = this.editPrompt.bind(this)
        this.deletePrompt = this.deletePrompt.bind(this)
    }

    componentDidMount(){
        this.getUsers()
    }

    getUsers(){
        var token = sessionStorage.getItem("Token")
        fetch(`https://wxloggerauthapi.azurewebsites.net/user/users`,{
                method: 'Get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + token
                }
            })
            .then(response =>{
                if(!response.ok)
                    throw new Error('Problem getting users')
                
                return response.json()
            })
            .then((json) => {
                this.setState({users: json})
            })
            .catch(error => {
                console.log(error)
        })
    }

    handleDialogOpen(){
        this.setState({showDialog: true})
    }
    
    handleDialogClose(){
        this.setState({showDialog: false})
        this.setState({dialogComponent: <div/>})
    }

    addPrompt(){
        this.handleDialogOpen()
        this.setState({dialogComponent: <AddUser handleClose = {this.handleDialogClose} updateUsers={this.getUsers}/>})
    }

    editPrompt(user){
        this.handleDialogOpen()
        this.setState({dialogComponent: <EditUser user={user} handleClose = {this.handleDialogClose} updateUsers={this.getUsers}/>})
    }

    deletePrompt(user){
        this.handleDialogOpen()
        this.setState({dialogComponent: <DeleteUser user={user} handleClose={this.handleDialogClose} updateUsers={this.getUsers}/>})
    }

    render(){  
        return(
            <div className="container">
                <TableContainer component={Paper}>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Email Address</TableCell>
                                <TableCell>First Name</TableCell>
                                <TableCell>Last Name</TableCell>
                                <TableCell>User Role</TableCell>
                                <TableCell>Active?</TableCell>
                                <TableCell></TableCell>
                                <TableCell> 
                                    <Tooltip title="Add New User">
                                        <IconButton onClick={()=>{this.addPrompt()}}>
                                            <Add/>
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.users.map(user => (
                                <TableRow key={user.userId}>
                                    <TableCell component="th" scope="row">
                                        {user.email}
                                    </TableCell>
                                    <TableCell>{user.firstName}</TableCell>
                                    <TableCell>{user.lastName}</TableCell>
                                    <TableCell>{user.userRole}</TableCell>
                                    <TableCell>{user.isActive ? "Yes" : "No"}</TableCell>
                                    <TableCell>
                                        <Tooltip title="Edit User">
                                            <IconButton onClick={()=>{this.editPrompt(user)}}>
                                                <Edit/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell>
                                        <Tooltip title="Delete User">
                                            <IconButton onClick={()=>{this.deletePrompt(user)}}>
                                                <Delete/>
                                            </IconButton>
                                        </Tooltip>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <CustomDialog
                    isOpen={this.state.showDialog}
                    handleClose={this.handleDialogClose}
                    children={this.state.dialogComponent}
                />
                <Backdrop open={this.state.users[0] === undefined ? true : false } style={backdropStyle}>
                    <CircularProgress color="inherit" />
                </Backdrop>                                
            </div>
        )
    }
}

export default UserIndex