import React, {Component} from 'react'
import '../../StyleSheets/Admin/adduser.css'
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

class EditUser extends Component{
    constructor(props){
        super(props);
        this.state={
            email: this.props.user.email,
            firstName: this.props.user.firstName,
            lastName: this.props.user.lastName,
            userRole: this.props.user.userRole,
            isActive: this.props.user.isActive
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handleCheckChange = this.handleCheckChange.bind(this)
    }


    handleChange(event) {
        event.preventDefault();
        const name = event.target.name
        const value = event.target.value
        this.setState({[name]: value})
    }

    handleCheckChange(event){
        if(event.target.checked){
            this.setState({isActive: true})
        }else{
            this.setState({isActive: false})
        }
    }

    handleSubmit(){
        var user = this.props.user

        user.email = this.state.email

        user.firstName = this.state.firstName

        user.lastName = this.state.lastName

        user.userRole = this.state.userRole

        user.isActive = this.state.isActive

        const token = sessionStorage.getItem("Token")

        fetch(`https://wxloggerauthapi.azurewebsites.net/user/user`,{
            method: 'PUT',
            body: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response =>{
            if(!response.ok){
                throw new Error('unauthorized')
            }
                
            return response.json()
        })
        .then((json) => {
            console.log("user added")
            this.props.updateUsers()
            this.props.handleClose()
        })
        .catch(error => {
            console.log("something failed")
        })
    }

    render(){
        return(
            <div>
                <h2 className="dialogText">Update User</h2>
                <form>
                    <div>
                        <TextField
                            required
                            id="filled-required"
                            label="Email Address"
                            name="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                        />  
                    </div>
                    <div>
                        <TextField
                            required
                            id="filled-required"
                            label="First Name"
                            name="firstName"
                            value={this.state.firstName}
                            onChange={this.handleChange}
                        />  
                    </div>
                    <div>
                        <TextField
                            required
                            id="filled-required"
                            label="Last Name"
                            name="lastName"
                            value={this.state.lastName}
                            onChange={this.handleChange}
                        />  
                    </div>
                    <div>
                        <FormControl>
                            <InputLabel>User Role  </InputLabel>
                            <Select
                            native
                            name="userRole"
                            value={this.state.userRole}
                            onChange={this.handleChange}
                            >
                                <option aria-label="None" value="" />
                                <option value='admin'>Admin</option>
                                <option value='observer'>Observer</option>
                                <option value='user'>User</option>
                            </Select>
                        </FormControl>
                    </div>
                    <div className="checkbox">
                        <FormControlLabel
                            control={
                                <Checkbox 
                                    onChange={this.handleCheckChange}
                                    color="primary"
                                    checked={this.state.isActive} 
                                />
                            }
                            label="Active?"
                        />
                    </div>
                </form>
                <div className="addButtons">
                    <DialogActions>
                        <Button onClick={this.handleSubmit}>
                            Update User
                        </Button>
                        <Button onClick={this.props.handleClose} color="primary">
                            Cancel
                        </Button>
                    </DialogActions>
                </div>
            </div>
            
        )
    }
} 

export default EditUser