import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import Auth from '../Login/Auth'

const AdminRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={props =>
        Auth.getAdminAuth() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/"
            }}
          />
        )
      }
    />
  );

  export default AdminRoute