import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import '../../StyleSheets/dashboard.css';
import TinyLineChart from '../Graphs/TinyLineChart'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

const backdropStyle = {
    color: '#fff',
    zIndex: 9999
}

class SnowStakeComponent extends Component{
    constructor(props){
        super(props)
        this.state = {
            snowStake: {},
            snowStakeData: []
        }

        this.getSnowStake =  this.getSnowStake.bind(this);
        this.getSnowStakeData =  this.getSnowStakeData.bind(this);
    }

    componentDidMount(){
        this.getSnowStake();
        this.getSnowStakeData();
    }

    getSnowStake(){
        const {sourceId} = this.props.match.params
        var token = sessionStorage.getItem("Token");
        fetch(`https://wxloggerobservationapi.azurewebsites.net/v1/latestsnowstake?sourceId=${sourceId}`,{
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem getting lastest snow stake summary')
            
            return response.json()
        })
        .then((json) => {
            this.setState({snowStake: json})
        })
        .catch(error => console.log(error))
    }

    getSnowStakeData(){
        const {sourceId} = this.props.match.params
        var token = sessionStorage.getItem("Token");
        fetch(`https://wxloggerobservationapi.azurewebsites.net/v1/snowstakes?sourceId=${sourceId}`,{
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem getting lastest snow stake summary')
            
            return response.json()
        })
        .then((json) => {
            this.setState({snowStakeData: json})
        })
        .catch(error => console.log(error))
    }

    render(){
        return(
            <div className="dashCard">
                <Grid container
                    spacing={0}
                    alignItems="center"
                    justify="center"
                >
                    <Grid item
                        xs={8}
                    >
                        <Card>
                            <Grid container
                                spacing={0}
                                alignItems="center"
                                justify="center"
                            >
                                <Grid item
                                    xs={6}
                                >
                                    <h1 className="cardTitle">Snow Stake Data</h1>
                                    <Grid container
                                        spacing={0}
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item xs={4}>
                                            <span className="dashLabel">Height New 24hr</span>
                                            <br/>
                                            <span className="dashValue">{this.state.snowStake.heightNew}"</span>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <span className="dashLabel">Height New 48hr</span>
                                            <br/>
                                            <span className="dashValue">{this.state.snowStake.heightNew48}"</span>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <span className="dashLabel">Height New 72hr</span>
                                            <br/>
                                            <span className="dashValue">{this.state.snowStake.heightNew72}"</span>
                                        </Grid>
                                    </Grid>
                                    <Grid container
                                        spacing={0}
                                        alignItems="center"
                                        justify="center"
                                    >
                                        <Grid item xs={4}>
                                            <span className="dashLabel">Height of Snow</span>
                                            <br/>
                                            <span className="dashValue">{this.state.snowStake.heightSnow}"</span>
                                        </Grid>
                                        <Grid item xs={4}>
                                            <span className="dashLabel">Total Snowfall</span>
                                            <br/>
                                            <span className="dashValue">{this.state.snowStake.totalSnowfall}"</span>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item
                                    xs={6}
                                >
                                    <p>Total Snowfall</p>
                                    <TinyLineChart
                                        data={this.state.snowStakeData}
                                        property="totalSnowfall"
                                        aspect={4/1}
                                        lineColor="#82ca9d"
                                    />
                                    <p>Height of Snow</p>
                                    <TinyLineChart
                                        data={this.state.snowStakeData}
                                        property="heightSnow"
                                        aspect={4/1}
                                        lineColor="#8884d8"
                                    />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
                <Backdrop open={this.state.snowStakeData[0] === undefined ? true : false } style={backdropStyle}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }
}

export default SnowStakeComponent