import React, { Component } from 'react';
import {LineChart, Line, ResponsiveContainer} from 'recharts';


class TinyLineChart extends Component {

  render() {  
    return (
      <ResponsiveContainer width="90%" aspect={this.props.aspect}>
        <LineChart data={this.props.data}>
        <Line 
          type="monotone" 
          dataKey={this.props.property} 
          stroke={this.props.lineColor} strokeWidth={2} 
          dot={false}
        />
      </LineChart>
      </ResponsiveContainer>
    );
  }
}


export default TinyLineChart