import React, {Component} from 'react'
import '../../StyleSheets/season.css'
import { Grid, Card, FormControl, Select, InputLabel, Button} from '@material-ui/core'
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend} from 'recharts'
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

const backdropStyle = {
    color: '#fff',
    zIndex: 9999
}

class SeasonComponent extends Component{
    constructor(props){
        super(props)
        this.state = {
            availablefields: [],
            data: [],
            lineOne:"",
            lineTwo:"",
            lineThree:""
        }

         this.getAvailableFields = this.getAvailableFields.bind(this)
         this.getData = this.getData.bind(this)
         this.handleChange = this.handleChange.bind(this)
         this.clearGraph = this.clearGraph.bind(this)
    }

    componentDidMount(){
        this.getAvailableFields();
        this.getData()
    }

    getAvailableFields(){
        var token = sessionStorage.getItem("Token");
        fetch(`https://wxloggerreportapi.azurewebsites.net/v1/dailyparameters?numeric=true`,{
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem getting availabe fields summary')
            
            return response.json()
        })
        .then((json) => {
            this.setState({availablefields: json})
        })
        .catch(error => console.log(error))
    }

    getData(){
        var token = sessionStorage.getItem("Token");
        fetch(`https://wxloggerreportapi.azurewebsites.net/v1/seasonreportdata`,{
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem getting season data summary')
            
            return response.json()
        })
        .then((json) => {
            this.setState({data: json})
        })
        .catch(error => console.log(error))
    }

    handleChange(event) {
        event.preventDefault();
        const name = event.target.name
        const value = event.target.value
        this.setState({[name]: value})
    }

    clearGraph(){
        this.setState({lineOne: ""})
        this.setState({lineTwo: ""})
        this.setState({lineThree: ""})
    }

    render(){
        var options = []
        this.state.availablefields.forEach((item, index) =>{
            options.push(<option key={index} value={item.columnName}>{item.columnName}</option>)
        })
        this.state.data.forEach(item => {
            var eventDate = item.CreateDate.split('T')
            var YearMonthDay = eventDate[0].split('-')
            item.PrettyDate = `${YearMonthDay[1]}/${YearMonthDay[2]}/${YearMonthDay[0]}`
        })
        return(
            <div className="dashCard">
                <Grid container
                    spacing={0}
                    alignItems="center"
                    justify="center"
                >
                    <Grid item
                        xs={11}
                    >
                        <Card>
                            <Grid container
                                spacing={0}
                                alignItems="center"
                                justify="center"
                            >
                                <Grid item
                                    xs={2}
                                >
                                    <div className="seasonSpacer"/>
                                    <h1 className="cardTitle">Season</h1>
                                </Grid>
                                <Grid item
                                    xs={2}
                                >
                                    <form>
                                        <FormControl>
                                            <InputLabel>Field One</InputLabel>
                                            <Select
                                                native
                                                name="lineOne"
                                                value={this.state.lineOne}
                                                onChange={this.handleChange}
                                            >
                                                <option aria-label="None" value="" />
                                                {options}
                                            </Select>
                                        </FormControl>
                                    </form>
                                </Grid>
                                <Grid item
                                    xs={2}
                                >
                                    <form>
                                        <FormControl>
                                            <InputLabel>Field Two</InputLabel>
                                            <Select
                                                native
                                                name="lineTwo"
                                                value={this.state.lineTwo}
                                                onChange={this.handleChange}
                                            >
                                                <option aria-label="None" value="" />
                                                {options}
                                            </Select>
                                        </FormControl>
                                    </form>
                                </Grid>
                                <Grid item
                                    xs={2}
                                >
                                    <form>
                                        <FormControl>
                                            <InputLabel>Field Three</InputLabel>
                                            <Select
                                                native
                                                name="lineThree"
                                                value={this.state.lineThree}
                                                onChange={this.handleChange}
                                            >
                                                <option aria-label="None" value="" />
                                                {options}
                                            </Select>
                                        </FormControl>
                                    </form>
                                </Grid>
                                <Grid item
                                    xs={1}
                                    
                                    >
                                        <div className="seasonSpacer"/>
                                        <Button className="notSelect" variant="outlined" onClick={this.clearGraph}>Clear</Button>
                                </Grid>
                            </Grid>
                            <ResponsiveContainer width="95%" aspect={3/1} minWidth={250}>
                                <LineChart data={this.state.data}>
                                    <Legend/>
                                    <XAxis dataKey="PrettyDate" ticks={['auto']}/>
                                    <YAxis/>
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <Tooltip/>
                                    {this.state.lineOne !== "" ? <Line type="monotone" dataKey={this.state.lineOne} stroke="#82ca9d" activeDot={{r: 8}}/> : <div/>}
                                    {this.state.lineTwo !== "" ? <Line type="monotone" dataKey={this.state.lineTwo} stroke="#8884d8" activeDot={{r: 8}}/> : <div/>}
                                    {this.state.lineThree !== "" ? <Line type="monotone" dataKey={this.state.lineThree} stroke="#F82727" activeDot={{r: 8}}/> : <div/>}
                                </LineChart>
                            </ResponsiveContainer>
                        </Card>
                    </Grid>
                </Grid>
                <Backdrop open={this.state.data[0] === undefined ? true : false } style={backdropStyle}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }
}

export default SeasonComponent