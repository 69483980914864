import React from 'react';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import PrivateRoute from './Components/Login/PrivateRoute'
import AdminRoute from './Components/Login/AdminRoute'
import ElevatedRoute from './Components/Login/ElevatedRoute'
import Auth from './Components/Login/Auth.js';
import LinkService from "./Components/Login/LinkService"
import FullAppBar from './Components/Display/FullAppBar';
import EmptyAppBar from './Components/Display/EmptyAppBar';
import LoginComponent from './Components/Login/LoginComponent';
import ResetComponent from './Components/Login/ResetComponent';
import ResetRequestComponent from './Components/Login/ResetRequestComponent';
import UserIndex from './Components/Admin/UserIndex';
import TelemetryComponent from './Components/DashBoard/TelemetryComponent';
import ObservationComponent from './Components/DashBoard/ObservationComponent';
import SnowStakeComponent from './Components/DashBoard/SnowStakeComponent';
import SeasonComponent from './Components/Season/SeasonComponent';
import SubmitObservationComponent from './Components/Submit/SubmitObservationComponent';
import SubmitSnowStakeComponent from './Components/Submit/SubmitSnowStakeComponent';
import SubmitMountainStatsComponent from './Components/Submit/SubmitMountainStatsComponent';
import ReportComponent from './Components/Reports/ReportComponent';
import ForecastComponent from './Components/DashBoard/Forecast/ForecastComponent';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: 0
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    title: {
      flexGrow: 1,
    },
    hide: {
    display: 'none',
    },
    drawer: {
    width: drawerWidth,
    flexShrink: 0,
    },
    drawerPaper: {
    width: drawerWidth,
    },
    drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
    },
    content: {
    flexGrow: 1,
    //marginLeft: 'auto',
    paddingLeft: drawerWidth,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
    },
    contentShift: {
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
    }
}));

const App = () => {
    var classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const [openDash, setDash] = React.useState(false)

    const [openObs, setObs] = React.useState(false)

    const handleList = (id) => {
        if(id === 1)
            setDash(!openDash)
        if(id === 3)
            setObs(!openObs)
    };

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    function handleSignout(){
        handleDrawerClose()
        Auth.signout()
    }

    return(
        <div className="container">
            <Router>
                <div className={classes.root}>
                    <Switch>
                        <Route exact path='/' component={()=><EmptyAppBar/>}/>
                        <Route exact path='/resetrequest' component={()=><EmptyAppBar/>}/>
                        <Route exact path='/reset/:email' component={()=><EmptyAppBar/>}/>
                        <Route path="/" component={()=>
                            <div>
                                <FullAppBar 
                                    classes={classes} 
                                    open={open} 
                                    handleSignout={()=>{handleSignout()}}
                                    handleDrawerOpen={()=>{handleDrawerOpen()}}
                                />
                            </div>
                            }
                        />
                    </Switch>
                    <main
                        className={clsx(classes.content, {
                        [classes.contentShift]: open,
                        })}
                    >
                        <Drawer
                            className={classes.drawer}
                            variant="persistent"
                            anchor="left"
                            open={open}
                            classes={{
                            paper: classes.drawerPaper,
                            }}
                        >
                            <div className={classes.drawerHeader}>
                                <IconButton onClick={handleDrawerClose}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </div>
                            <Divider />
                            <List>
                                {LinkService.getLinks(handleList, openDash, openObs)}
                            </List>
                        </Drawer>
                        <div>
                            <Route exact path='/' component={LoginComponent}/>
                            <Route exact path='/resetrequest' component={ResetRequestComponent}/>
                            <Route exact path='/reset/:payload' component={ResetComponent}/>
                            <PrivateRoute exact path= '/dash/telemetry/:sourceId' component={TelemetryComponent}/>
                            <PrivateRoute exact path= '/dash/observation/:sourceId' component={ObservationComponent}/>
                            <PrivateRoute exact path= '/dash/snowstake/:sourceId' component={SnowStakeComponent}/>
                            <PrivateRoute exact path= '/dash/forecast/:sourceId' component={ForecastComponent}/>
                            <PrivateRoute exact path='/season' component={SeasonComponent}/>
                            <ElevatedRoute exact path='/submit/observation/:sourceId' component={SubmitObservationComponent}/>
                            <ElevatedRoute exact path='/submit/snowstake/:sourceId' component={SubmitSnowStakeComponent}/>
                            <ElevatedRoute exact path='/submit/mountainstats/:sourceId' component={SubmitMountainStatsComponent}/>
                            <ElevatedRoute exact path='/reports' component={ReportComponent}/>
                            <AdminRoute exact path='/admin' component={UserIndex}/>
                        </div>
                    </main>
                </div> 
            </Router>
        </div>  
    )
    
}

export default App;
