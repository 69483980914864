import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root:{
        fontSize: 20,
        margin: 10,
        textAlign: 'left'
    },
    heading:{
        color: '#0F508A'
    }
}))

const ForecastDetailTile = (props) =>{
    var classes = useStyles();

    return(
        <div className={classes.root}>
            <span className={classes.heading}><b>{props.data.name} - </b></span>
            <span>{props.data.detailedForecast}</span>
        </div>
    )

}

export default ForecastDetailTile