import React, {Component} from 'react';
import { WiWindDeg, WiWindy, WiThermometer} from 'weather-icons-react';
import '../../StyleSheets/livedisplay.css'
import {getWindDirection} from '../../Utils'

class LiveDataComponent extends Component{
    constructor(props){
        super(props);
        this.state = {
            CurrentReading: {}
        }
        this.getReadings = this.getReadings.bind(this)
    }
    componentDidMount(){
        if(sessionStorage.getItem('Token')){
            this.getReadings()
            this.timer = setInterval(() => this.getReadings(), 10000)
        }
    }
    
    componentWillUnmount(){
        clearInterval(this.timer)
    }
    
    getReadings(){
        var token = sessionStorage.getItem("Token");
        fetch(`https://wxloggertelemeteryapi.azurewebsites.net/v1/lastreading`, {
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem getting latest reading')
            
            return response.json()
        })
        .then((json) => {
            this.setState({CurrentReading: json})
        })
        .catch(error => console.log(error))
    }

    render(){
        return(
            <div>
                <span className="wxIcon"><WiThermometer size={20}/></span>
                <span><p>{this.state.CurrentReading.temp}°F</p></span>
                <span className="wxIcon"><WiWindy size={25}/></span>
                <span><p>{this.state.CurrentReading.windSpeed} mph</p></span>
                <span className="wxIcon"><WiWindDeg size={20}/></span>
                <span><p>{getWindDirection(this.state.CurrentReading.windBearing).abbr}</p></span>
                <span className="inlineSpacer"/>
            </div>
        )
    }
}

export default LiveDataComponent;