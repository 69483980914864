import React, {Component} from 'react';
import {Grid, Card, FormControl, InputLabel, Select, Tooltip, TextField, Button} from '@material-ui/core';
import MountainStatsTable from './Tables/MountainStatsTable';
import DatePickerComponent from '../DatePickers/DatePickerComponent';
import Backdrop from '@material-ui/core/Backdrop'
import CircularProgress from '@material-ui/core/CircularProgress'

const backdropStyle = {
    color: '#fff',
    zIndex: 9999
}

class SubmitMountainStatsComponent extends Component{
    constructor(props){
        super(props);
        this.state = {
            mountainStats:{},
            mutableFields:{
                surfaceConditions: "",
                acreage: 0,
                liftsOpen: 0,
                runsOpen: 0
            },
            error:<div/>
        }
        this.handleChange = this.handleChange.bind(this);
        this.getMountainStatsById = this.getMountainStatsById.bind(this);
        this.updateMountainStats = this.updateMountainStats.bind(this);
        this.validateAndPostObs = this.validateAndPostObs.bind(this)
        this.getLatestMountainStats = this.getLatestMountainStats.bind(this)
    }

    componentDidMount(){
        this.getLatestMountainStats()
    }

    handleChange(event) {
        const name = event.target.name
        const value = event.target.value
        this.setState(prevState => ({
            mutableFields: {
                ...prevState.mutableFields,
                [name]: value
            }
        }))
        this.setState({error:<div/>})
    }

    getLatestMountainStats(){
        this.setState({error:[]})
        var token = sessionStorage.getItem("Token");
        fetch(`https://wxloggerreportapi.azurewebsites.net/v1/latestmountainstat`,{
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem getting lastest mountain stats summary')
            
            return response.json()
        })
        .then((json) => {
            this.setState({mountainStats: json})
            this.setState({mutableFields: json})
        })
        .catch(error => console.log(error))
    }

    getMountainStatsById(dailySummaryKey){
        this.setState({error:[]})
        var token = sessionStorage.getItem("Token");
        fetch(`https://wxloggerobservationapi.azurewebsites.net/v1/mountainstat?dailySummaryKey=${dailySummaryKey}`,{
            method: 'Get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem getting mountain stats')
            
            return response.json()
        })
        .then((json) => {
            this.setState({mountainStats: json})
            this.setState({mutableFields: json})
        })
        .catch(error => {
            console.log(error)
            this.setState({error: [<p key={1000} className="error">No mountain stats data for this date!</p>]})
        })
    }

    updateMountainStats(obj){
        var token = sessionStorage.getItem("Token");
        fetch(`https://wxloggerobservationapi.azurewebsites.net/v1/mountainstat`,{
            method: 'PUT',
            body: JSON.stringify(obj),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            }
        })
        .then(response =>{
            if(!response.ok)
                throw new Error('Problem updating snow stake')
            
            return response.json()
        })
        .then((json) => {
            this.setState({mountainStats: json})
        })
        .catch(error => console.log(error))
    }

    validateFields(){
        var errors = []
        var validationFields = [
            {
                key: this.state.mutableFields.surfaceConditions,
                displayName: "Surface conditions",
                exampleValue: "Powder",
                isNumber: false
            },
            {
                key: this.state.mutableFields.acreage,
                displayName: "Acreage",
                exampleValue: "200",
                isNumber: true
            },
            {
                key: this.state.mutableFields.liftsOpen,
                displayName: "Lifts open",
                exampleValue: "10",
                isNumber: true
            },
            {
                key: this.state.mutableFields.runsOpen,
                displayName: "Runs open",
                exampleValue: "75",
                isNumber: true
            }
        ]

        validationFields.forEach(element => {
            if(element.key === undefined || element.key === ''){
                var presenceErrorString = `${element.displayName} cannot be blank` 
                errors.push(<p className="error">{presenceErrorString}</p>)
            }
            if(element.isNumber === true){ 
                if(!Number.isFinite(parseFloat(element.key))){
                    var numericErrorString = `${element.displayName} must be a valid value. Example Value: ${element.exampleValue}`
                    errors.push(<p className="error">{numericErrorString}</p>)
                }
            }
        })
        return errors
    }

    validateAndPostObs(){
        var errors = this.validateFields()
        if(errors.length > 0){
            this.setState({error: errors[0]})
        }else{
            const dto = {...this.state.mountainStats, ...this.state.mutableFields}
            this.updateMountainStats(dto)
        }
    }


    render(){
        return(
            <div className="container">
                <Grid container
                    spacing={0}
                    alignItems="center"
                    justify="center"
                >
                    <Grid item
                        xs={11}
                    >
                        <Card>
                            <MountainStatsTable
                                mountainStats={this.state.mountainStats}
                            />
                            <Grid container
                                spacing={0}
                                alignItems="center"
                                justify="center"
                            >
                                <Grid item
                                    xs={6}
                                >
                                    <DatePickerComponent
                                        sourceId={this.props.match.params}
                                        update={this.getMountainStatsById}
                                    />
                                </Grid>
                                <Grid item
                                    xs={6}
                                >
                                    <div>
                                        <Tooltip title="Lifts Open">
                                            <TextField
                                                required
                                                label="Lifts Open"
                                                name="liftsOpen"
                                                value={this.state.mutableFields.liftsOpen}
                                                onChange={this.handleChange}
                                            />
                                        </Tooltip>  
                                    </div>
                                    <div>
                                        <Tooltip title="Runs Open">
                                            <TextField
                                                required
                                                label="Runs Open"
                                                name="runsOpen"
                                                value={this.state.mutableFields.runsOpen}
                                                onChange={this.handleChange}
                                            />
                                        </Tooltip>  
                                    </div>
                                    <div>
                                        <Tooltip title="Acreage">
                                            <TextField
                                                required
                                                label="Acreage"
                                                name="acreage"
                                                value={this.state.mutableFields.acreage}
                                                onChange={this.handleChange}
                                            />
                                        </Tooltip>  
                                    </div>
                                    <div>
                                        <FormControl>
                                            <InputLabel htmlFor="age-native-simple">Surface Conditions</InputLabel>
                                            <Select
                                            native
                                            name="surfaceConditions"
                                            value={this.state.mutableFields.surfaceConditions}
                                            onChange={this.handleChange}
                                            >
                                                <option aria-label="" value="" />
                                                <option value="Powder">Powder</option>
                                                <option value="Packed Powder">Packed Powder</option>
                                                <option value="Groomed">Groomed</option>
                                                <option value="Spring Conditions">Spring Conditions</option>
                                            </Select>
                                        </FormControl>
                                    </div>
                                    <Button size="large" onClick={this.validateAndPostObs}>Save and Calculate</Button>
                                    {this.state.error}
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                </Grid>
                <Backdrop open={this.state.mountainStats.acreage === undefined ? true : false } style={backdropStyle}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        )
    }
}

export default SubmitMountainStatsComponent